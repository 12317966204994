import { LeadgreaseApiCapture } from "@/modules/api/index";
export default class LeadgreaseEndpointsV1 {
    constructor() {
        this.endpoints = {
            [LeadgreaseApiCapture.ModeProduction]: {
                capture: "https://eu1-api.leadgrease.com/capture/pingpost",
                response: "https://eu1-api.leadgrease.com/capture/response",
                sponsorList: "https://eu1-api.leadgrease.com/capture/getSponsorList"
            },
            [LeadgreaseApiCapture.ModePreview]: {
                capture: "https://eu1-api.leadgrease.com/capture/pingpost",
                response: "https://eu1-api.leadgrease.com/capture/response",
                sponsorList: "https://eu1-api.leadgrease.com/capture/getSponsorList"
            },
            [LeadgreaseApiCapture.ModeDemo]: {
                capture: "https://eu1-api.leadgrease.com/capture/pingpost",
                response: "https://eu1-api.leadgrease.com/capture/response",
                sponsorList: "https://eu1-api.leadgrease.com/capture/getSponsorList"
            },
            prod: {
                capture: "https://eu1-api.leadgrease.com/capture/pingpost",
                response: "https://eu1-api.leadgrease.com/capture/response",
                sponsorList: "https://eu1-api.leadgrease.com/capture/getSponsorList"
            },
            dev: {
                capture: "http://localhost:8003/capture/pingpost",
                response: "http://localhost:8003/capture/response",
                sponsorList: "http://localhost:8003/capture/getSponsorList"
            }
        };
    }
    getEndpoint(mode) {
        return this.endpoints[mode];
    }
}
