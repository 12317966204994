import ContainerConditional from '@/modules/form/components/ContainerConditional';
import Select from '@/modules/form/components/Select';
import Birthday from '@/modules/form/components/Birthday';
import GoogleMapsAddress from '@/modules/form/components/GoogleMapsAddress';
import WrongAddressMessage from '@/modules/form/components/WrongAddressMessage';
import FormSwiperSlide from '@/modules/form/components/FormSwiperSlide';
const ComponentModules = [
    Birthday,
    Select,
    ContainerConditional,
    WrongAddressMessage,
    GoogleMapsAddress,
    FormSwiperSlide
];
export { WrongAddressMessage, ContainerConditional, GoogleMapsAddress, Birthday, Select, FormSwiperSlide, ComponentModules };
