import Component from "./Component";
export default class Select extends Component {
    constructor(el) {
        super(el);
        this.createPlaceholder();
    }
    checkIsOptionExist(option) {
        let element = this.el.querySelector(`option[value="${option}"]`);
        return (element) ? true : false;
    }
    createPlaceholder() {
        let placeholder = this.el.getAttribute('placeholder');
        if (!this.checkIsOptionExist(placeholder) && placeholder) {
            let option = document.createElement('option');
            option.setAttribute('hidden', '');
            option.setAttribute('value', '');
            option.setAttribute('selected', '');
            option.innerHTML = placeholder;
            this.el.append(option);
        }
    }
}
Select.typeRef = "C-SELECT";
Select.tagRef = "SELECT";
