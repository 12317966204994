/****
 *  Usage
 *  var lgConfig= {
 *      appId: "Account ID",
 *      apiKey: "Provider Key",
 *      <optional> form: "Form Key",
 *
 *  }
 * var lgCustom= {
 *     onResponse: function(){
 *          // Action
 *     }
 * }
 * 
 * var stepsConfig = { 
 *      "countSteps": "Total steps",
 *      "currentStep": " Current step" 
 * };
 * @param Config
 * @returns {{formKey: (*|string)}}
 */

import { Leadgrease } from "@/leadgrease";
let apiVersion = document.currentScript.getAttribute("api-version");
let accountCode = document.currentScript.getAttribute("account-code");
let autoLoad = ((document.currentScript.hasAttribute("auto-load"))
    && document.currentScript.getAttribute("auto-load") === "false") ? false : true;
let appId = document.currentScript.getAttribute("app-id");
let isDev = document.currentScript.hasAttribute("is-dev") ? true : false;

if (autoLoad) {
    console.log("Leadgrease: Autoload");
    let config = {
        lgConfig,
        accountCode
    }

    if (appId != undefined && appId != null) {
        config.lgConfig = {
            ...config.lgConfig,
            appId
        }
    }

    if (isDev != undefined && isDev != null) {
        config.lgConfig = {
            ...config.lgConfig,
            isDev
        }
    }
    if (typeof lgCustom !== "undefined") {
        config = {
            ...config,
            lgCustom,
        }
    }
    if (typeof lgPixels !== "undefined") {
        config = {
            ...config,
            lgPixels
        }
    }
    if (typeof stepsConfig !== "undefined") {
        config = {
            ...config,
            stepsConfig
        }
    }

    if (typeof apiVersion !== "undefined") {
        config = {
            ...config,
            apiVersion
        }
    }
    window.leadgrease = new Leadgrease(config);
}
