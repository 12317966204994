export function buildFormJson(Config) {
    let formOrigin = document.getElementById(Config.formContainer);
    var arr = formOrigin.elements, i = 0, l = arr.length;
    var fillFields = Config.fillFields, ii = 0, ll = fillFields.length;
    let formHasJson = {
        formKey: Config.formKey
    };
    for (var formFields in formOrigin.elements) {
        for (var field in Config.fillFields) {
            if (formOrigin.elements[formFields].name === field)
                formHasJson[Config.fillFields[field]] = formOrigin.elements[formFields].value
        }
    }
    for (var field in Config.customFields) {
        formHasJson[field] = Config.customFields[field];
    }

    return formHasJson;
}

export function autoPopulateQueryToForm() {
    let populateKey = Object.keys(getPopulateQuery())
    if (populateKey.length)
        Object.entries(populateKey).forEach(([key, value]) => {
            if (typeof (document.getElementsByName(value)) !== "undefined" && typeof (document.getElementsByName(value)[0]) !== "undefined")
                document.getElementsByName(value)[0].value = getParameterByName(value);
        });
}

export function stringToHTML(str) {
    var parser = new DOMParser();
    var doc = parser.parseFromString(str, 'text/html');
    return doc.body.firstChild;
};

export function valueExist(value, element) {
    let options = element.querySelectorAll('option');
    let find = Array.from(options).find((option) => {
        return value == option.value;
    })

    return find ? true : false;
}

export function isLeapYear(year) {
    year = parseInt(year);
    if (year % 4 != 0) {
        return false;
    } else if (year % 400 == 0) {
        return true;
    } else if (year % 100 == 0) {
        return false;
    } else {
        return true;
    }
}

export async function fetchIP() {
    return fetch("https://fsnf450i3b.execute-api.eu-west-1.amazonaws.com/info")
        .then(async function (response) {
            let data = await response.json();
            return data;
        });
}

export function getAgeFromBirthDate(birthDate) {
    return Math.floor((new Date() - new Date(birthDate).getTime()) / 3.15576e+10);
}

export function getFieldsFromEl(el) {
    el = (el) ? el : this.el;
    let inputs = Array.from(el.querySelectorAll('input'));
    let selects = Array.from(el.querySelectorAll('select'));
    let fields = inputs.concat(selects);
    return fields;
}

export function getArrayFromEl(el) {
    let data = {};
    let fields = getFieldsFromEl(el);
    for (var index in fields) {
        var field = fields[index];
        if (field.name && field.name !== "") {
            let name = field.name;
            let type = field.type;
            if (type == "checkbox") {
                data[name] = field.checked;
            }
            else if (type == "radio") {
                if (!(name in data) && field.checked && field.value != "null") {
                    data[name] = field.value;
                }
            } else if (type == 'select-one') {
                if (field.value && field.value != "")
                    data[name] = field.value;
            } else {
                data[name] = field.value;
            }
        }
    }
    return data;
}

export function extractValuesFromMessage(message) {
    // Utilizamos una expresión regular para encontrar todos los valores entre comillas
    const matches = message.match(/'([^']*)'/g);
    let result = {
        field: null,
        value: null,
        reason: null
    };
    if (matches && matches.length == 3) {
        const field = matches[0].replace(/'/g, '');
        const value = matches[1].replace(/'/g, '');
        const reason = matches.length >= 3 ? matches[2].replace(/'/g, '') : null;

        result = {
            field,
            value,
            reason
        };
    } else if (matches && matches.length >= 4) {
        const field = matches[2].replace(/'/g, '');
        const value = matches[3].replace(/'/g, '');
        result = {
            field,
            value,
            reason: message
        };
    }
    console.log(result);
    return result;
}

export function applyValidationStylesOnField(el, isValid) {
    if (isValid == true) {
        el.classList.remove('is-invalid');
    } else {
        if (el.type == "checkbox") {
            el.parentNode.classList.add('bg-warning');
        } else {
            el.classList.add('is-invalid');
        }
        el.focus();
    }

    return isValid;
}

export function findParentElementByQuery(element, query) {
    let parent = element.parentElement;

    while (parent) {
        if (parent.matches(query)) {
            return parent;
        }
        parent = parent.parentElement;
    }

    return null;  // Devuelve null si no se encontró ningún elemento padre que cumpla la consulta.
}

export function getFetchConfig({ method = 'GET', params, data, query, headers }) {

    if (typeof data === 'object') {
        data = JSON.stringify(data);
    }

    return {
        headers: {
            "content-type": "application/json",
            ...headers
        },
        mode: 'cors',
        cache: 'reload',
        method,
        body: data

    }
}

export async function fetcher(url, { method = 'GET', params, data, query, baseURL, headers }) {

    let config = getFetchConfig({
        method,
        params,
        data,
        query,
        headers
    });

    url = (baseURL) ? `${baseURL}${url}` : url;

    let response = await fetch(url, config);

    response = await response.json();
    return response;
}