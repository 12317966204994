import { getFieldsFromEl } from "@/utils/index";
import Component from "./Component";
import InstanceComponentsFromElUseCase from "@/modules/form/Application/UseCases/InstanceComponentsFromElUseCase";
import { ContainerConditional } from "@/modules/form/components/index";
export default class FormSwiperSlide extends Component {
    constructor(el, form) {
        super(el);
        this.components = [];
        this.form = form;
        this.slideIndex = this.el.getAttribute("data-lg-slide-index");
        this.components = (new InstanceComponentsFromElUseCase()).run(el, this.form);
    }
    getConditions() {
        return this.components.filter((component) => {
            return ContainerConditional.typeRef == component.type;
        });
    }
    validateConditions() {
        return this.getConditions().map((condition) => {
            return condition.validate();
        });
    }
    checkFieldsNotInConditionalContainer() {
        let fields = getFieldsFromEl(this.el);
        return fields.filter((field) => {
            for (let i = 0; i < this.getConditions().length; i++) {
                const condition = this.getConditions()[i];
                let find = condition.getFields().find((cf) => {
                    return cf.id == field.id;
                });
                if (find)
                    return false;
            }
            return true;
        });
    }
}
FormSwiperSlide.typeRef = "C-FORM-SWIPER-SLIDE";
