import { ComponentModules } from "@/modules/form/components/index";
export default class instanceComponentsFromElUseCase {
    run(el, form) {
        let components = [];
        let items = el.querySelectorAll("[data-lg-type],[data-slg-type],select");
        for (const ModuleName in ComponentModules) {
            if (Object.hasOwnProperty.call(ComponentModules, ModuleName)) {
                const Module = ComponentModules[ModuleName];
                items.forEach((item) => {
                    if (item.getAttribute("data-lg-type") == Module.typeRef
                        || item.getAttribute("data-slg-type") == Module.typeRef
                        || item.tagName == Module.tagRef) {
                        components.push(new Module(item, form));
                    }
                });
            }
        }
        return components;
    }
}
