import { getAgeFromBirthDate, getFieldsFromEl } from "@/utils/index";
import Component from "./Component";
export default class ContainerConditional extends Component {
    constructor(el, form) {
        super(el);
        this.conditions = [];
        this.fields = [];
        this.form = form;
        this.noHide = el.hasAttribute("data-lg-no-hide");
        this.fields = getFieldsFromEl(this.el);
        this.hide();
        this.initCondition();
        this.validate();
    }
    initCondition() {
        let conditions = this.el.getAttribute('data-lg-conditions');
        if (conditions && conditions != "")
            this.conditions = (new Function("return " + conditions + ";")());
    }
    getFields() {
        return this.fields;
    }
    resetFields() {
        return this.fields.forEach((field) => {
            if (field.type != 'radio' && field.value != "") {
                field.value = "";
                var eventChange = new Event('change');
                field.dispatchEvent(eventChange);
            }
            if (field.type == 'radio' && field.checked) {
                field.checked = false;
                var eventChange = new Event('change');
                field.dispatchEvent(eventChange);
            }
        });
    }
    getConditions() {
        return this.conditions;
    }
    show() {
        if (!this.noHide) {
            this.el.style.display = "block";
        }
        this.isRequired(true);
        this.isValidated(true);
    }
    hide() {
        if (!this.noHide) {
            this.el.style.display = "none";
        }
        this.isRequired(false);
        this.isValidated(false);
    }
    validate() {
        let isValid = this.checkCondition();
        if (isValid) {
            this.show();
        }
        else {
            this.resetFields();
            this.hide();
        }
        return isValid;
    }
    isRequired(valid) {
        this.fields.forEach((field) => {
            field.required = valid;
        });
    }
    isValidated(toogle) {
        this.fields.forEach((field) => {
            field.toggleAttribute("data-lg-no-validate", !toogle);
        });
    }
    update() {
    }
    checkCondition() {
        for (let i = 0; i < this.conditions.length; i++) {
            let name = this.conditions[i]['name'];
            let condition = this.conditions[i]['condition'];
            let value = this.conditions[i]['value'];
            let dataForm = this.form.data;
            let dataFormValue = dataForm[name];
            if (name == 'birth_date') {
                dataFormValue = getAgeFromBirthDate(dataFormValue);
            }
            switch (condition) {
                case 'equal':
                    if (!(dataFormValue == value)) {
                        return false;
                    }
                    break;
                case 'not_equal':
                    if (!(dataFormValue != value)) {
                        return false;
                    }
                    break;
                case 'greater_than':
                    if (!(dataFormValue >= value)) {
                        return false;
                    }
                    break;
                case 'less_than':
                    if (!(dataFormValue <= value)) {
                        return false;
                    }
                    break;
                case 'in_list':
                    if (!(value.includes(dataFormValue))) {
                        return false;
                    }
                    break;
                case 'not_in_list':
                    if (value.includes(dataFormValue)) {
                        return false;
                    }
                    break;
                default:
            }
        }
        return true;
    }
}
ContainerConditional.typeRef = "C-CONTAINER-CONDITIONAL";
