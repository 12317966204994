import { Loader } from "@googlemaps/js-api-loader";
import Component from "./Component";
import WrongAddressMessage from "./WrongAddressMessage";
export default class GoogleMapsAddress extends Component {
    constructor(el, form) {
        super(el);
        this.form = form;
        if (this.el.dataset.lgRegion) {
            this.loader = new Loader({
                apiKey: "AIzaSyDVUPSShdP2GMCBzxH-qogLgo99C9Mm1dw",
                version: "3.exp",
                libraries: ["places"],
                region: this.el.dataset.lgRegion
            });
        }
        else {
            this.loader = new Loader({
                apiKey: "AIzaSyDVUPSShdP2GMCBzxH-qogLgo99C9Mm1dw",
                version: "3.exp",
                libraries: ["places"]
            });
        }
        this.addressField = this.el.querySelector("[name=address]");
        this.streetNumberField = this.el.querySelector("[name=street_number]");
        this.cpField = this.form.el.querySelector(`[name=${this.el.dataset.lgAutocompletePostalCode}]`);
        this.provinceField = this.form.el.querySelector(`[name=${this.el.dataset.lgAutocompleteProvince}]`);
        this.loader.load().then(() => {
            this.geocoder = new google.maps.Geocoder();
            this.autocomplete = new google.maps.places.Autocomplete(this.addressField, {
                types: ['address'],
                componentRestrictions: {
                    country: (this.el.dataset.lgRegion) ? this.el.dataset.lgRegion : []
                }
            });
            google.maps.event.addListener(this.autocomplete, 'place_changed', () => {
                var place = this.autocomplete.getPlace();
                if (this.cpField)
                    this.cpField.value = null;
                if (this.streetNumberField)
                    this.streetNumberField.value = null;
                if (this.provinceField)
                    this.provinceField.value = null;
                if (place.address_components) {
                    this.checkAddressComponents(place.address_components);
                }
            });
            this.cpField.addEventListener('change', (e) => {
                this.checkFieldIsValid(this.cpField);
                this.geocoder.geocode({
                    "address": this.getSearchAddress(),
                }, (results, status) => {
                    if (status == google.maps.GeocoderStatus.OK && results.length > 0) {
                        this.checkAddressComponents(results[0].address_components);
                        if (!this.postalCodeIsValid(this.cpField.value, results[0].address_components)
                            || !this.streetNumberIsValid(this.streetNumberField.value, results[0].address_components)) {
                            this.showWrongAddressMessage();
                        }
                        else {
                            this.hideWrongAddressMessage();
                        }
                    }
                });
            });
            this.streetNumberField.addEventListener('change', (e) => {
                this.checkFieldIsValid(this.streetNumberField);
                this.geocoder.geocode({
                    "address": this.getSearchAddress(),
                }, (results, status) => {
                    console.log(results, status);
                    if (status == google.maps.GeocoderStatus.OK && results.length > 0) {
                        this.checkAddressComponents(results[0].address_components);
                        if (!this.streetNumberIsValid(this.streetNumberField.value, results[0].address_components)
                            || !this.postalCodeIsValid(this.cpField.value, results[0].address_components)) {
                            this.showWrongAddressMessage();
                        }
                        else {
                            this.hideWrongAddressMessage();
                        }
                    }
                });
            });
        });
    }
    getWrongAddressMessage() {
        return this.form.components.find((component) => {
            return component.type == WrongAddressMessage.typeRef;
        });
    }
    showWrongAddressMessage() {
        let message = this.getWrongAddressMessage();
        if (message)
            message.show();
    }
    hideWrongAddressMessage() {
        let message = this.getWrongAddressMessage();
        if (message)
            message.hide();
    }
    getSearchAddress() {
        return `${this.addressField.value}, ${this.streetNumberField.value}, ${this.cpField.value}`;
    }
    checkFieldIsValid(fieldElement, addressComponent = null) {
        if (!fieldElement.value) {
            fieldElement.classList.add("is-invalid");
        }
        else {
            fieldElement.classList.remove("is-invalid");
        }
    }
    checkAddressComponents(addressComponents) {
        for (var i = 0; i < addressComponents.length; i++) {
            if (this.cpField && addressComponents[i].types[0] == 'postal_code') {
                if (!this.cpField.value)
                    this.cpField.value = addressComponents[i].long_name;
                this.cpField.classList.remove("is-invalid");
            }
            else if (this.cpField && !this.cpField.value) {
                this.cpField.classList.add("is-invalid");
            }
            if (this.provinceField && addressComponents[i].types[0] == 'administrative_area_level_2') {
                if (!this.provinceField.value)
                    this.provinceField.value = addressComponents[i].long_name;
                this.provinceField.classList.remove("is-invalid");
            }
            else if (this.provinceField && !this.provinceField.value) {
                this.provinceField.classList.add("is-invalid");
            }
            if (this.streetNumberField && addressComponents[i].types[0] == 'street_number') {
                if (!this.streetNumberField.value)
                    this.streetNumberField.value = addressComponents[i].long_name;
                this.streetNumberField.classList.remove("is-invalid");
                // Delete street number in addres text
                this.addressField.value = this.addressField.value.replace(`${this.streetNumberField.value}, `, "");
            }
            else if (this.streetNumberField && !this.streetNumberField.value) {
                this.streetNumberField.classList.add("is-invalid");
            }
        }
    }
    streetNumberIsValid(streetAddres, addressComponents) {
        let find = addressComponents.find((addressComponent) => {
            return addressComponent.types[0] == "street_number";
        });
        return !!(find) && find.long_name == streetAddres;
    }
    postalCodeIsValid(postalCode, addressComponents) {
        let find = addressComponents.find((addressComponent) => {
            return addressComponent.types[0] == "postal_code";
        });
        return !!(find) && find.long_name == postalCode;
    }
}
GoogleMapsAddress.typeRef = "C-GOOGLE-MAPS-ADDRESS";
