import Component from "./Component";
import { valueExist } from "@/utils/index";
export default class Birthday extends Component {
    constructor(el) {
        super(el);
        this.days = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
        this.months = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];
        this.dayField = this.el.querySelector('[data-slg-type="C-SELECT-DAY"]') || this.el.querySelector("[data-type='birthday-day']");
        ;
        this.monthField = this.el.querySelector("[data-slg-type='C-SELECT-MONTH']") || this.el.querySelector("[data-type='birthday-month']");
        this.yearField = this.el.querySelector("[data-slg-type='C-SELECT-YEAR']") || this.el.querySelector("[data-type='birthday-year']");
        this.dateField = this.el.querySelector("[data-slg-type='C-BIRTHDAY-CALENDAR']") || this.el.querySelector("[data-type='birthday-calendar']");
        this.followInQuery = this.el.hasAttribute("data-lg-follow-in-query");
        this.initSelectMonth();
        this.initSelectDay(31);
        this.initSelectYear();
        this.initFieldDate();
    }
    proxyData(data) {
        if (this.dateField) {
            data.birth_date = this.dateField.value;
        }
        else {
            delete data[this.dayField.name];
            delete data[this.monthField.name];
            delete data[this.yearField.name];
            let month = parseInt(this.monthField.value) + 1;
            let monthAsString = (month) ? ((month < 10) ? `0${month}` : month) : '00';
            let day = parseInt(this.dayField.value);
            let dayAsString = (day) ? ((day < 10) ? `0${day}` : day) : '00';
            let year = parseInt(this.yearField.value);
            let yearAsString = (year) ? `${year}` : '0000';
            data.birth_date = yearAsString + "-" + monthAsString + "-" + dayAsString;
        }
        return data;
    }
    initFieldDate() {
        if (this.dateField) {
            var currentDate = new Date();
            // Restar 18 años a la fecha actual
            var maxDate = new Date(currentDate.getFullYear() - 18, currentDate.getMonth(), currentDate.getDate());
            var maxDateString = maxDate.toISOString().slice(0, 10);
            // Establecer la fecha mínima en el input de tipo date
            this.dateField.setAttribute("max", maxDateString);
            this.dateField.type = "text";
            this.dateField.addEventListener("focus", () => {
                this.dateField.type = "date";
            });
            this.dateField.addEventListener("blur", () => {
                if (!this.dateField.value)
                    this.dateField.type = "text";
            });
        }
    }
    initSelectMonth() {
        if (this.dayField) {
            this.monthField.innerHTML = '';
            this.monthField.onchange = (changeMonth) => {
                let option = changeMonth.target;
                if (!isNaN(parseInt(option.value)))
                    this.initSelectDay(this.days[option.value]);
            };
            for (let month = 0; month < this.months.length; month++) {
                let MonthOpt = document.createElement("option");
                MonthOpt.value = month.toString();
                MonthOpt.text = this.months[month];
                this.monthField.add(MonthOpt, null);
            }
        }
    }
    initSelectDay(days = 31) {
        if (!this.dayField)
            return;
        let value = this.dayField.value;
        this.dayField.innerHTML = "";
        for (let day = 1; day < days + 1; day++) {
            let DayOpt = document.createElement("option");
            DayOpt.value = day.toString();
            DayOpt.text = day.toString();
            this.dayField.add(DayOpt, null);
        }
        if (value && valueExist(value, this.dayField))
            this.dayField.value = value;
    }
    initSelectYear() {
        if (!this.yearField)
            return;
        let toYear = (new Date().getFullYear()) - 17;
        let fromYear = toYear - 80;
        this.yearField.innerHTML = '';
        for (let year = toYear; year > fromYear; year--) {
            let yeaerFieldOption = document.createElement("option");
            yeaerFieldOption.value = year.toString();
            yeaerFieldOption.text = year.toString();
            this.yearField.add(yeaerFieldOption, null);
        }
    }
}
Birthday.typeRef = "C-BIRTHDAY";
